import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  BankSetup,
  OpeningBalanceSetup,
  OpeningCashBalance,
  PosSetup,
  StaffSetup,
  TankSetup,
  WalletSetup,
} from './RouteComponents';

const RouteSetup = () => {
  const env = process.env.ENVIRONMENT;
  return (
    <Switch>
      <Route path="/account/setup/bunk/tank" component={TankSetup} />
      <Route path="/account/setup/bunk/wallet" component={WalletSetup} />
      <Route path="/account/setup/bunk/bank" component={BankSetup} />
      <Route path="/account/setup/bunk/staff" component={StaffSetup} />
      <Route path="/account/setup/bunk/pos" component={PosSetup} />
      <Route
        path="/account/setup/bunk/account-opening-balance"
        component={OpeningBalanceSetup}
      />
      <Route
        path="/account/setup/bunk/opening-cash-balance"
        component={OpeningCashBalance}
      />
      <Route path="/account/setup/bunk/*">
        <Redirect to={`/account/setup/bunk/tank`} />
      </Route>
    </Switch>
  );
};

export { RouteSetup };
