import axios from 'axios';

import { authHeader } from '@H';
import { authService } from '@S';

export default {
  getCurrentSMSPackageList: () =>
    axios({
      method: 'GET',
      headers: authHeader(1),
      url: `${process.env.API_URL}/bunk/sms-recharge/list/`,
    }),
};
