import React from 'react';

import { Header, Navbar, NavPills } from '@C';
import ErrorBoundary from '@C/Error';
import { RouteSetup } from '@C/Settings';

const routes = [
  {
    name: 'Reminder',
    path: '/account/setup/app/reminder-notifications',
    display: false,
  },
  {
    name: ' SMS',
    path: '/account/setup/app/sms',
    display: true,
  },
  {
    name: 'Reset Account',
    path: '/account/setup/app/reset-account',
  },
  {
    name: 'Profit Margin',
    path: '/account/setup/app/profit-margin',
  },
];

const Settings = () => {
  return (
    <main>
      <div className="page-wrapper">
        <Header title="Bunk Setup" />
        <Navbar />
        <div className="page-content">
          <section className="page-components">
            <div className="page-title">
              <h1> App Settings</h1>
            </div>
            <ErrorBoundary>
              <NavPills routes={routes} />
              <RouteSetup />
            </ErrorBoundary>
          </section>
        </div>
      </div>
    </main>
  );
};

export { Settings };
