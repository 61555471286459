import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  ProfitMargin,
  Reminder,
  ResetAccount,
  SmsSetup,
} from '@C/Settings/RouteComponents';

const RouteSetup = () => {
  const env = process.env.ENVIRONMENT;
  return (
    <Switch>
      {env === 'development' && (
        <Route
          path="/account/setup/app/reminder-notifications"
          component={Reminder}
        />
      )}
      <Route path="/account/setup/app/sms" component={SmsSetup} />
      <Route path="/account/setup/app/reset-account" component={ResetAccount} />
      <Route path="/account/setup/app/profit-margin" component={ProfitMargin} />
      <Route path="/account/setup/app/*">
        <Redirect to={`/account/setup/app/sms`} />
      </Route>
    </Switch>
  );
};

export { RouteSetup };
