import React, { useEffect, useState, useRef } from 'react';
import services from '@C/Admin/Users/_utils/data';
import SMSList from './smsList';
import Select from 'react-select';
import { SMSPackageList } from '@C/Utils';
import { authService } from '@S';
import { authHeader } from '@H';
import axios from 'axios';
import { toast } from 'react-toastify';

const ActivateSMS = () => {
  const [state, setState] = useState({
    bunk_id: '',
    mobile_number: '',
    package_id: '',
    amount: '',
    no_of_sms: '',
    dealerList: [],
    limit: 20,
    offset: 1,
  });
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const abortControllerRef = useRef(null);
  const [searching, setSearching] = useState(false);
  const [updated, setUpdated] = useState(false);

  useEffect(() => {
    if (state.mobile_number) {
      // Clear any previous timeout
      const timer = setTimeout(() => {
        getDealerDetails();
        setSearching(true);
      }, 1000);

      // Cleanup function to clear the timeout and abort the previous request
      return () => {
        clearTimeout(timer);
        if (abortControllerRef.current) {
          abortControllerRef.current.abort();
        }
      };
    }
  }, [state.mobile_number]);

  const handleMobileChange = (e) => {
    setState({ ...state, mobile_number: e.target.value });
  };

  const handleDealerChange = (e) => {
    setState({ ...state, bunk_id: e.value });
  };

  const getDealerDetails = async () => {
    abortControllerRef.current = new AbortController();
    const signal = abortControllerRef.current.signal;

    try {
      const response = await services.getDealersList(state, { signal });
      const tmp = response.data.data.results;
      const categoryOptions = tmp.map((item) => ({
        value: item.account_id,
        label: item.name,
      }));
      setState((prevState) => ({
        ...prevState,
        dealerList: response.data.data.results,
      }));
      setOptions(categoryOptions);

      setSearching(false);
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.log(error);
        setSearching(false);
      }
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handlePackageChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      package_id: e.value,
      amount: e.amount,
      no_of_sms: e.sms,
    }));
  };

  const activateForm = (e) => {
    e.preventDefault();

    const formData = {
      package_id: state.package_id,
      bunk_id: state.bunk_id,
      is_paid: true,
      paid_amount: state.amount,
    };
    const headers = authHeader(1);

    axios
      .post(`${process.env.API_URL}/sms-recharge/`, formData, { headers })
      .then(() => {
        toast.success('SMS package activated successfully');
        //clear form
        setState({
          ...state,
          bunk_id: '',
          mobile_number: '',
          package_id: '',
          amount: '',
          no_of_sms: '',
        });
        setUpdated((prev) => !prev);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <>
      <section className="widget form-card full-width">
        <header>
          <h3> Activate SMS </h3>
        </header>
        <main>
          <form className="inline">
            <div className="input-wrapper with-label">
              <label>Dealer mobile no.</label>
              <input
                type="text"
                onChange={handleMobileChange}
                value={state.mobile_number}
                name="mobile_number"
                disabled={searching}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Dealer name</label>
              <Select
                onChange={handleDealerChange}
                isClearable={true}
                options={options}
                className="select-box-container"
                classNamePrefix="raw-select"
                name="account_id"
                placeholder="Select dealer"
                value={options.filter((obj) => obj.value === state.bunk_id)}
                isDisabled={searching || false}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>Select package</label>
              <SMSPackageList
                handleSMSChange={handlePackageChange}
                value={state.package_id}
              />
            </div>
            <div className="input-wrapper with-label">
              <label>No. of SMS </label>
              <input
                type="text"
                value={state.no_of_sms}
                name="no_of_sms"
                disabled
              />
            </div>
            <div className="item-actions">
              <button className="submit" onClick={activateForm}>
                Activate
              </button>
            </div>
          </form>
        </main>
      </section>
      <SMSList key={updated} />
    </>
  );
};

export { ActivateSMS };
