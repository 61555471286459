import React, { Component } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { CustomerList } from '@C/CreditCustomers/_utils/';
import services from '@C/CreditCustomers/_utils/data';
import { formatCurrency, TableGrid } from '@C/TableGrid';
import DropLoaderLarge from '@C/Utils/DropLoaderLarge';
import { authHeader } from '@H';

const sortOptions = [
  { value: 'alpha', label: 'Alphabetical order' },
  { value: 'asc', label: 'Outstanding asc' },
  { value: 'desc', label: 'Outstanding desc' },
];

const col = [
  {
    name: 'Name',
    key: 'all',
    formatter(props) {
      return (
        <>
          <Link
            to={`/account/credit-customers/credit-customer-ledger/${props.uuid}`}
            title="View Customer Ledger"
          >
            {props.basic_details.first_name} {props.basic_details.last_name}
          </Link>
        </>
      );
    },
  },
  {
    name: 'Mobile',
    key: 'basic_details',
    formatter(props) {
      return <>{props.mobile}</>;
    },
  },
  {
    name: 'Outstanding',
    key: 'billing_cycle',
    formatter(props) {
      return <>{formatCurrency(props.balance)}</>;
    },
  },
  {
    name: 'Credit limit',
    key: 'billing_cycle',
    formatter(props) {
      return <>{formatCurrency(props.payment_range)}</>;
    },
  },
];

const enabledActions = [
  {
    action: 'sms',
    roles: ['Manager', 'Dealer'],
    icon: 'sms',
    title: 'Send SMS',
    callback: (item) => {
      console.log('SMS', item);
    },
  },
  {
    action: 'Delete',
    roles: ['Manager', 'Dealer'],
  },
  {
    action: 'Edit',
    roles: ['Manager', 'Dealer'],
  },
];

class AllCreditCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      filteredList: [],
      showModal: false,
      selectedData: '',
      offset: 1,
      limit: 9999,
      loading: true,
      count: 0,
      credit_customer: '',
      sortOrder: 'alpha',
    };
  }
  closeModal = () => {
    this.setState({ showModal: false });
  };

  componentDidMount() {
    if (this.state.userList.length === 0) {
      services
        .getCreditCustomerList(
          this.state.limit,
          this.state.offset,
          this.state.credit_customer,
        )
        .then((response) => {
          this.setState(
            {
              userList: response.data.data.results,
              filteredList: response.data.data.results,
              count: response.data.data.count,
              loading: false,
              credit_customer: null,
            },
            () => {
              this.sortData();
              this.handlePagination(this.state.offset);
            },
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  sortData = () => {
    let tmp = [...this.state.filteredList];
    const order = this.state.sortOrder;
    if (order === 'alpha') {
      tmp.sort((a, b) => {
        return a.basic_details.first_name.localeCompare(
          b.basic_details.first_name,
        );
      });
    } else {
      tmp.sort((a, b) => {
        return order === 'asc'
          ? a.billing_cycle.balance - b.billing_cycle.balance
          : b.billing_cycle.balance - a.billing_cycle.balance;
      });
    }
    this.setState({ filteredList: tmp });
  };

  editDetails = (data) => {
    modal_type = 'edit';
    this.setState({ selectedData: data, showModal: true });
  };

  handleSortOrderChange = (e) => {
    this.setState({ sortOrder: e.value }, function () {
      this.sortData();
    });
  };

  handlePagination = (e) => {
    this.setState({ offset: e }, function () {
      this.getCreditCustomerList;
    });
  };

  handleCustomerChange = (e) => {
    if (e.value) {
      const list = this.state.userList.filter((item) => item.uuid === e.value);
      this.setState(
        { credit_customer: e.value, filteredList: list },
        function () {
          this.getCreditCustomerList;
        },
      );
    } else {
      this.setState({ credit_customer: '--' }, function () {
        this.getCreditCustomerList;
        window.location.reload();
      });
    }
  };

  confirmDelete = (item) => {
    confirmAlert({
      title: 'Confirm credit customer deletion',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteDetails(item),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };
  confirmBlacklist = (item) => {
    confirmAlert({
      title: 'Confirm blacklisting the customer',
      message: 'Are you sure',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.blacklistCustomer(item),
        },
        {
          label: 'No',
          onClick: () => false,
        },
      ],
    });
  };
  blacklistCustomer = (id) => {
    // e.preventDefault();
    const formData = {
      uuid: item.uuid,
      notes: 'blacklisted through customer list. Notes to be added later',
    };
    const headers = authHeader(1);
    axios
      .post(
        `${process.env.API_URL}/credit-customer/bunk/block-list/`,
        formData,
        {
          headers,
        },
      )
      .then(() => {
        toast.success('Customer blacklisted successfully', {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        this.setState({
          uuid: '',
          notes: '',
        });
      })
      .catch((error) => {
        toast.error(error.response.data.error, {
          position: 'bottom-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      });
  };
  deleteDetails = (item) => {
    // e.preventDefault();
    services
      .delete(
        `${process.env.API_URL}/credit-customer/bunk/customer/?credit_customer_id=${item.uuid}`,
      )
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          toast.success('Credit customer deleted successfully', {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          this.getCreditCustomerList;
          this.props.updateCCList();
        } else {
          toast.error(response.data.message, {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  sendSMS = (item) => {
    const formData = {
      customer_id: item.uuid,
      sms_type: 'CUSTOMER_OUTSTANDING',
    };
    const headers = authHeader(1);

    axios
      .post(`${process.env.API_URL}/bunk/send-sms/`, formData, { headers })
      .then(() => {
        toast.success(`SMS sent to ${item.basic_details.mobile} successfully`);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  render() {
    const { filteredList } = this.state;
    const enabledActions = [
      {
        action: 'sms',
        roles: ['Manager', 'Dealer'],
        icon: 'sms',
        title: 'Send SMS',
        callback: (item) => {
          this.sendSMS(item);
        },
      },
      {
        action: 'Delete',
        roles: ['Manager', 'Dealer'],
      },
      {
        action: 'Edit',
        roles: ['Manager', 'Dealer'],
      },
    ];

    return (
      <>
        <section className="widget full-width list-table">
          <header>
            <h3>Credit Customer List</h3>
          </header>
          <main>
            <div className="mini-summary">
              <ul>
                <li>
                  <span>Total outstanding amount: </span>
                  <span>
                    {new Intl.NumberFormat('en-IN', {
                      style: 'currency',
                      currency: 'INR',
                    }).format(
                      filteredList.reduce(
                        (a, b) => a + (b['billing_cycle']['balance'] || 0),
                        0,
                      ) || 0,
                    )}
                  </span>
                </li>
              </ul>
            </div>
            <div className="table-filters">
              <div className="filter-wrapper">
                <div className="filter-group">
                  <label>Credit customer:</label>
                  <CustomerList
                    onCustomerChange={this.handleCustomerChange}
                    value={this.state.credit_customer}
                  />
                </div>
                <div className="filter-group">
                  <label>Sort order</label>
                  <Select
                    name="sort_order"
                    options={sortOptions}
                    onChange={this.handleSortOrderChange}
                    value={sortOptions.filter(
                      ({ value }) => value === this.state.sortOrder,
                    )}
                    defaultValue={{
                      value: 'alpha',
                      label: 'Alphabetical order',
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="table-wrapper">
              {(() => {
                if (this.state.loading) {
                  return <DropLoaderLarge />;
                } else {
                  return (
                    <TableGrid
                      col={col}
                      row={filteredList}
                      offset={this.state.offset}
                      count={this.state.count}
                      limit={this.state.limit}
                      handlePagination={this.handlePagination}
                      onEdit={this.props.onEdit}
                      onReport={this.props.onReport}
                      onDelete={this.confirmDelete}
                      enabledActions={enabledActions}
                      exportOptions={{
                        fileName: `Credit Customer - ${this.state.start_date} to ${this.state.end_date}.pdf`,
                        reportName: 'Credit Customer Report',
                        reportDate: `Date - ${dayjs(
                          this.state.start_date,
                        ).format(
                          'D MMM YY',
                        )} to ${dayjs(this.state.end_date).format('D MMM YY')}`,
                        reportTotal: `Total outstanding amount -  ${
                          filteredList.reduce(
                            (a, b) => a + (b['billing_cycle']['balance'] || 0),
                            0,
                          ) || 0
                        }`,
                        reportQuantity: '',
                        reportSales: '',
                        reportTesting: '',
                        reportBowser: '',
                        reportCustomerName: '',
                        reportGst: '',
                        reportOpeningBalance: '',
                        reportClosingBalance: '',
                        reportSaleTotal: '',
                        reportTCSTotal: '',
                      }}
                    />
                  );
                }
              })()}
            </div>
          </main>
        </section>
      </>
    );
  }
}

export { AllCreditCustomer };
