import React, { useState, useEffect } from 'react';
import { TableGrid } from '@C/TableGrid';
import axios from 'axios';
import { toast } from 'react-toastify';
import { authHeader } from '@H';
import services from '@C/Admin/SmsSetup/_utils/data';

const col = [
  {
    name: 'Date',
    key: 'created_on',
    type: 'date',
  },
  {
    name: 'Dealer',
    key: 'bunk',
    formatter(props) {
      return <>{props.name}</>;
    },
  },
  {
    name: 'mobile',
    key: 'bunk',
    formatter(props) {
      return <>{props.mobile_number}</>;
    },
  },
  {
    name: 'Package requested',
    key: 'package',
    formatter(props) {
      return <>{props.name}</>;
    },
  },
  {
    name: 'Request Status',
    key: 'recharge_status',
  },
];

const SMSList = (props) => {
  const [list, setList] = React.useState([]);
  const [state, setState] = useState({
    offset: 1,
    count: 0,
    limit: 20,
  });

  useEffect(() => {
    getSMSRequestList();
  }, [, state]);

  const getSMSRequestList = () => {
    services.getSMSrequestList(state).then((response) => {
      setList(response.data.data.results);
    });
  };

  const handlePagination = (e) => {
    setState((prev) => ({ ...prev, offset: e }));
  };

  const activateSMS = (e, action) => {
    const formData = {
      bunk_id: e.bunk.account_id,
      uuid: e.uuid,
      recharge_status: action,
    };
    const headers = authHeader(1);

    axios
      .put(`${process.env.API_URL}/sms-recharge/`, formData, { headers })
      .then(() => {
        toast.success('SMS request activated successfully');
        getSMSRequestList();
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const enabledActions = [
    {
      action: 'Approve',
      roles: ['Site Admin'],
      icon: 'approve',
      callback: (e) => {
        activateSMS(e, 'ACTIVE');
      },
    },
    {
      action: 'Reject',
      roles: ['Site Admin'],
      icon: 'reject',
      callback: (e) => {
        activateSMS(e, 'DECLINE');
      },
    },
  ];

  return (
    <>
      <section className="widget full-width">
        <header className="title-wrapper">
          <h3> All Request for SMS </h3>
        </header>
        <main className="content-wrapper">
          <TableGrid
            col={col}
            row={list}
            offset={state.offset}
            count={state.count}
            limit={state.limit}
            handlePagination={handlePagination}
            enabledActions={enabledActions}
          />
        </main>
      </section>
    </>
  );
};

export default SMSList;
