import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import services from './data';

const SMSPackageList = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getSMSPackageList = async () => {
      try {
        const response = await services.getSMSPackageList();
        const tmp = response.data.data.results;
        const categoryOptions = tmp.map((item) => ({
          value: item.uuid,
          label: `${item.sms} - sms / ₹${item.amount}`,
          amount: item.amount,
          sms: item.sms,
        }));
        setOptions(categoryOptions);
      } catch (error) {
        console.log(error);
      }
    };

    getSMSPackageList();
  }, []);

  const handleChange = useCallback(
    (selectedOption) => {
      const option = selectedOption || { value: '', label: '', amount: 0 };
      props.handleSMSChange(option);
    },
    [props.handleSMSChange],
  );
  return (
    <Select
      onChange={handleChange}
      isClearable={true}
      options={options}
      className="select-box-container"
      classNamePrefix="raw-select"
      name="package"
      placeholder=""
      value={options.filter(
        (obj) => obj.value === props.value.value || props.value,
      )}
      isDisabled={props.disabled || false}
    />
  );
};

export { SMSPackageList };
